<template>
  <div>
    <div class="panel">
      <div class="flex between-center">
        <div class="flex items-center">
          <PanelTitle title="审核用户任务" :title-class="['fs20']"/>
          <div class="flex items-center red-text ml24">
            <!-- <span>已审核：178</span>
            <span class="ml16">待审核：178</span> -->
          </div>
        </div>
        <div class="flex items-center">
          <div :class="['btn-batch', serialNos.length>0 ? 'active':'']" @click="passPatch">
            <img v-if="serialNos.length == 0" src="@/assets/images/selected-gray.png">
            <img v-else src="@/assets/images/selected.png">
            <span>批量通过</span>
          </div>
          <div :class="['btn-select-all', 'ml8', isSelectAll ? 'active':'']" @click="selectAll">
            <img v-if="isSelectAll" src="@/assets/images/select-all-active.png">
            <img v-else src="@/assets/images/select-all.png">
            <span>全选</span>
          </div>
        </div>
      </div>
    </div>
    <div class="task-list" v-if="reviewList && reviewList.length>0">
      <div class="task" v-for="(item, index) in reviewList" :key="index">
        <el-checkbox-group v-model="serialNos" @change="handleCheckChange">
          <div class="top-row">
            <el-checkbox :label="item.submitSerialNo">{{ "" }}</el-checkbox>
            <div class="flex items-center">
              <div class="btn-refuse" @click="handleRefuse(item)">驳回</div>
              <div class="btn-pass" @click="handlePass(item)">通过</div>
            </div>
          </div>
          <div class="info">
            <div class="flex items-center">
              <!-- <img class="avatar" src="@/assets/images/avatar-default.png"> -->
              <LoadImage class="avatar" :src="item.submitUserHeadImg" :default="require('@/assets/images/avatar-default.png')"></LoadImage>
              <div>
                <div class="name">{{item.submitNickName}}</div>
                <div class="flex items-center">
                  <div class="id">ID：{{item.submitUser}}</div>
                  <img class="copy" src="@/assets/images/icon-copy.png" @click="handleCopy(item.submitUser)">
                </div>
              </div>
            </div>
            <div class="time">
              <div>领取时间：{{item.takeTime}}</div>
              <div class="mt8">提交时间：{{item.submitTime}}</div>
            </div>
          </div>
          <!-- 提交信息 -->
          <div v-for="(step,i) in item.compareSteps" :key="i">
            <div class="sort-row">
              <div class="flex items-center">
                <div class="sort-no">{{i + 1}}</div>
                <div>{{step.stepTitle}}</div>
              </div>
              <img v-if="step.stepType == 2" class="img-copy" src="@/assets/images/icon-copy.png" @click="handleCopy(step.submitContent)">
            </div>
            <div class="imgs" v-if="step.stepType == 1">
              <div class="example">
                <div class="exm">示例图</div>
                <LoadImage class="img" :src="step.originContent" :preview="true"></LoadImage>
              </div>
              <LoadImage class="img" :src="step.submitContent" :preview="true"></LoadImage>
            </div>
            <el-input v-if="step.stepType == 2" type="textarea" disabled :value="step.submitContent"></el-input>
          </div>
        </el-checkbox-group>
        
      </div>
    </div>
    <div class="panel mt12 empty" v-else>
      <Empty/>
    </div>
    <!-- 分页控制 -->
    <div class="flex justify-center mt24">
      <Pagination :current-page="curPage + 1" @next-click="nextPage" @prev-click="prevPage"/>
    </div>
    <!-- 驳回弹窗 -->
    <RefusePopup ref="refusePopupRef" @update="getReviewList"></RefusePopup>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import Empty from '@/components/Empty'
import Pagination from '@/components/Pagination'
import RefusePopup from './components/RefusePopup'
import { taskManageApi } from '@/api/task.js'
import { copyUtil, bufferToHex, hexToArrayBuffer } from '@/utils/util.js'
import Setting from '@/setting.js'
import { enc, dec } from '@/utils/sec.js'
export default {
  name: "TaskReview",
  components: {
    PanelTitle,
    LoadImage,
    Empty,
    Pagination,
    RefusePopup
  },
  data() {
    return {
      reviewList: [],
      taskId: '',
      serialNos: [],
      eventSource: null,
      isSelectAll: false,
      curPage: 0
    }
  },
  created() {
    this.taskId = this.$route.query.taskId
    this.getReviewList()
  },
  methods: {
    handleCopy(val) {
      copyUtil(val).then(() => {
        this.$tips({message: '复制成功', type: 'success'})
      }).catch(err => {
        console.log(err)
      })
    },
    getReviewList() {
      taskManageApi({
        'type': 19,
        'taskId': this.taskId,
        'extParam': {
          startPage: this.curPage,
          // pageSize: 6
        }
      }).then(res => {
        console.log('查询审核任务', res)
        const list = res.data.auditTaskList || []
        if(list.length > 0 || this.curPage == 0) {
          this.reviewList = list
        } else {
          this.$tips({type: 'warning', message: '没有更多数据了'})
          this.curPage--
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    handleRefuse(info) {
      this.$refs.refusePopupRef.open({taskId: this.taskId, serialNo: info.submitSerialNo})
    },
    handlePass(info) {
      taskManageApi({
        'type': 1,
        'taskId': this.taskId,
        'extParam': {
          'auditAction': 1,
          'serialNo': info.submitSerialNo
        }
      }).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.getReviewList()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    connectEventSource() {
      return new Promise((resolve, reject) => {
        if (!!window.EventSource) {
          const userId = localStorage.getItem('USER_ID')
          const did = localStorage.getItem('DEVICE_ID')
          const reqData = {
            userId: userId,
            scene: 'TPC_BAT'
          }
          // data加密
          let jsonStr = JSON.stringify(reqData)
          let encRes = enc(jsonStr, this.$store.state.dataKey)
	        const encResult = bufferToHex(encRes.buffer)
          console.log('加密结果', encResult)
          let source = new EventSource(`${Setting.HTTP_REQUEST_URL}sse/connect/${did}?sse=${encResult}`)
          this.eventSource = source
          source.onopen = e => {
            console.log("建立连接。。。", e)
            // setMessageInnerHTML("建立连接。。。");
            resolve(e)
          }
          source.onmessage = e => {
            console.log('接收数据', e, source.readyState)
            const buffer = hexToArrayBuffer(e.data)
            let str = dec(new Uint8Array(buffer), this.$store.state.dataKey)
            let res = JSON.parse(str)
            console.log('解密结果', res)
            if(res?.data) {
              const { state, serialNo, userId } = res.data
              const uid = localStorage.getItem('USER_ID')
              if(userId != uid) return
              // 1-进行中 2-完成
              if(state == 1) {
                const index = this.reviewList.findIndex(item => item.submitSerialNo == serialNo)
                if(index != -1) this.reviewList.splice(index, 1)
              }
              if(state == 2) {
                this.$tips({message: '操作成功', type: 'success'})
                this.getReviewList()
                source.close()
              }
            }
          }
          let count = 0
          source.onerror = e => {
            console.log("接收error", e, this.eventSource.readyState)
            count++
            if(count >= 3) source.close()
            // if(e.readyState != EventSource.CLOSED) {
            //   source.close()
            //   this.getReviewList()
            // }
          }
          source.addEventListener('close', e => {
            console.log("-----close-----", e)
            source.close()
          })
        } else {
          this.$tips({message: '当前浏览器不支持该操作，请换个浏览器尝试', type: 'error'})
        }
      })
    },
    async passPatch() {
      console.log(this.serialNos)
      if(this.reviewList.length == 0) return
      if(this.serialNos.length == 0) return this.$tips({message: '请勾选要通过审核的任务', type: 'warning'})
      try {
        console.log('ess连接状态', this.eventSource && this.eventSource.readyState)
        if(!this.eventSource || this.eventSource.readyState == EventSource.CLOSED) {
          await this.connectEventSource()
        }
        const formData = {
          'type': 20,
          'taskId': this.taskId,
          'extParam': {
            'auditAction': 1,
            'serialNos': JSON.stringify(this.serialNos)
          }
        }
        console.log('formData',formData)
        taskManageApi(formData).then(res => {
          console.log('批量审核通过', res)
          this.getReviewList()
          // this.$tips({message: res.msg, type: 'success'})
        }).catch(err => {
          this.$tips({message: err, type: 'error'})
        })
      } catch (err) {
        console.log('err', err)
        this.$tips({message: '操作失败', type: 'error'})
      }
      
    },
    handleCheckChange() {
      this.isSelectAll = this.serialNos.length == this.reviewList.length
      console.log('选择',this.serialNos)
    },
    selectAll() {
      if(this.isSelectAll) {
        this.serialNos = []
        this.isSelectAll = false
      } else {
        this.serialNos = this.reviewList.map(item => item.submitSerialNo)
        this.isSelectAll = true
      }
      
    },
    nextPage() {
      this.curPage++
      this.getReviewList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getReviewList()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-batch, .btn-select-all {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #B0B3BF;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}
.btn-select-all {
  color: #2A2A2A;
}
.active {
  color: #25CF36;
}

.task-list {
  display: flex;
  flex-wrap: wrap;
  ::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 32px;
  }

  ::-webkit-scrollbar-track {
    background-color: #F6F6F6;
    // border-radius: 32px;
  }
  .task {
    width: 390px;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px;
    margin: 12px 12px 0 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 16px;
    margin: 0 -24px;
    border-bottom: 1px solid #f5f5f5;
  }
  .btn-refuse {
    font-size: 16px;
    color: var(--theme-color-red);
    cursor: pointer;
  }
  .btn-pass {
    font-size: 16px;
    color: #25CF36;
    margin-left: 16px;
    cursor: pointer;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 12px;
    }
    .name {
      font-size: 16px;
      font-weight: bold;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }
    .id {
      font-size: 12px;
      color: #B0B3BF;
      max-width: 75px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .copy {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      cursor: pointer;
    }
    .time {
      font-size: 12px;
      color: #65666F;
    }
  }
  .sort-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 16px;
    .sort-no {
      width: 16px;
      height: 16px;
      line-height: 14px;
      text-align: center;
      border-radius: 8px;
      font-size: 12px;
      color: #FFA536;
      border: 1px solid #FFA536;
      margin-right: 6px;
    }
    .img-copy {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .imgs {
    display: flex;
    justify-content: space-between;
    .example {
      position: relative;
      .exm {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        line-height: 20px;
        padding: 0 8px;
        background-color: #FFA536;
        border-radius: 0 0 10px 0;
        color: #fff;
        font-size: 12px;
      }
    }
    .img {
      width: 165px;
      height: 298px;
      object-fit: contain;
      background-color: #f5f5f5;
    }
  }
}
</style>